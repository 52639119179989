<body>
  <div class="card-body">
    <div class="container" style="margin-top: 20px; max-width: 50%;">
      <div class="row float-right mr-2">
        <mat-slide-toggle [checked]="false" [(ngModel)]="check" 
          (ngModelChange)="setColorMode()" title="{{this.colorService.getColorMode() | titlecase}} on"></mat-slide-toggle>
      </div>
      
      <h1 id="pg-title">MILESTONE UPDATE</h1>
      <small>Execute a schedule refresh on Nostra.</small> 
      <div class="update-pg bg">
        <div class="panel">
          <div class="float-left mr-3"><h3 style="line-height: 1.8">CHOOSE SITE : </h3> </div>
          <div> 
            <form [formGroup]="form">
              <select formControlName="site">
                <option *ngFor="let s of sites" value="{{s.site}}" 
                  [ngClass]="(this.getAccountRoles().includes(s.site))?
                      'd-block':'d-none'"
                >{{ s.description }}</option>
              </select>
              <!-- 'SYNCHRONISE' BUTTON -->
              <button id="sync-btn" (click)="sync()" [disabled]="this.form.value.site == ''" class="btn btn-warning">
                <mat-icon>autorenew</mat-icon> Synchronise</button>
              <br/>
              
              <!-- ERROR RED TEXTS -->
              <small class="warning" *ngIf="hasSiteError">{{ this.errorMessage }}</small>
            </form>
          </div>
        </div>
        <hr class="sync" *ngIf="isScheduled">
        <div class="sync panel" *ngIf="isScheduled">
          <h3>RESULTS: </h3>
          <div class="d-flex">
            <table id="results-table" class="table-bordered float-left">
                <tbody>
                <tr>
                  <th class="table-active pl-2 pr-2 text-nowrap ">Message</th>
                  <td class="p-1 text-nowrap w-100 pl-2">{{ this.nostraProjects.message }}</td>
                </tr>

                <tr>
                  <th class="table-active pl-2 pr-2 text-nowrap border-bottom">Batch ID</th>
                  <td class="p-1 text-nowrap w-100 pl-2">{{ this.nostraProjects.batch_id }}</td>
                </tr>

                <tr>
                  <th class="w-25 table-active pl-2 border-bottom">Status</th>
                    <td class="p-1 statusCol pl-2" [ngClass]="{'SUCCESSFUL': 'success', 'ERRORS': 'fail','WAITING': 'running'}[nostraProjects.status]"> 
                      <img class="mx-auto ms-auto d-block" *ngIf="nostraProjects.status == 'SUCCESSFUL'" src="../../assets/success.png" height="20px"/>
                      <img class="mx-auto ms-auto d-block" *ngIf="nostraProjects.status == 'ERRORS'" src="../../assets/fail.png" height="20px"/>
                      <span class="mx-auto ms-auto d-block" class="hourglass"
                            *ngIf="nostraProjects.status == 'WAITING' || 
                                   nostraProjects.status == 'RUNNING'">&#x231b;</span>
                      <span style="margin-left: 14px;">{{ this.nostraProjects.status }}</span>
                    </td>
                </tr>
              </tbody>
            </table>
            <button id="refresh-btn"  *ngIf="hasProjectError == false" (click)="refresh(nostraProjects.batch_id)" 
              class="btn btn-warning float-right align-self-end">
              <mat-icon>autorenew</mat-icon>Refresh</button>
          </div>

          <hr>
          <table id="proj-table" class="table-bordered w-100">
            <tbody class="text-center">
              <th class="table-active p-2">PRIMAVERA PROJECT #</th>
              <tr *ngFor="let proj of nostraProjects.projects">
                <td class="p-2">
                {{ this.proj.primaveraNumber }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br/>
        <small class="warning" *ngIf="hasProjectError == true">{{this.errorMessage}}</small>
      </div> 
  
    </div>
</div>
</body>

