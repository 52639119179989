import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'base64Decode'})
export class Base64Pipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
      return JSON.parse(JSON.stringify(window.atob(value)));
  }
}
@Pipe({name: 'prettyprint'})
export class PrettyPrintPipe implements PipeTransform {
  transform(val) {
      return JSON.stringify(val, null, 2);
  }
}
