import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { NgForm, FormGroup } from '@angular/forms';

import { Batch } from "@app/_models/batch";
import { BatchesService } from './batches.service';
import { PrettyPrintPipe } from '@app/_helpers/base64.pipe';
import { ColorModeService } from '../_services/color-mode.service'
import { AppComponent } from '@app/app.component';
import { PrettyPrintService } from '@app/_services/pretty-print.service';

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./../app.component.scss', './batches.component.scss'],
  providers: [BatchesService, PrettyPrintPipe]
})
export class BatchesComponent implements AfterViewInit, OnInit {
  
  check: boolean; // check if browser is in dark mode
  popUp: any;     // popup box object

  count: number;  // batch page count 
  pageSize: number = 5;
  pageIndex: number = 1;

  hasError: boolean;
  isCopied: boolean = false;
  isPopUpOpen: boolean;
  
  form: FormGroup;
  searchFieldOpen = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('formDirective') private formDirective: NgForm;
  dataSource: MatTableDataSource<Batch>;
  displayedColumns: string[] = ['batchId', 'site', 'object', 'status', 'message','startTime', 'details'];

  constructor( public  colorService: ColorModeService, 
               private BatchesService: BatchesService,
               private app: AppComponent, 
               private prettyPrintService: PrettyPrintService) { }

  ngOnInit() {
    this.getBatches(true);
    this.colorService.load('.app-frame');
    this.check = this.colorService.getCheck();
    this.form = this.app.buildForm(this.displayedColumns);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  /**
   * Get batches from Nostra Sync API
   * @param is_init is first time getting batches
   */
  getBatches(is_init: boolean): void {
    this.app.setTriggerLoad(true);
    this.pageSize = (is_init? 5 : this.pageSize);
    this.pageIndex = (is_init? 1 : this.pageIndex);

    this.BatchesService.getBatches(this.pageSize, this.pageIndex)
     .subscribe(
        (batches) => {
          this.hasError = false;

          let result: Batch[] = batches.body;
          if (result.length == 0) { 
            result = [{'batch_input':{'site':'', 'object':''}, 'batch_stats':{'':''}, 'status':'', 'batch_id': '', 
              'message':'Loading successful: No batch found.', 'batch_start_time':'','resources':{'':''}}];
          } 

          this.dataSource = new MatTableDataSource<Batch>(result);
          this.dataSource.paginator = this.paginator;
          this.count = Number(batches.headers.get('x-page-count'));
          this.pageSize = Number(batches.headers.get('x-page-size'));
          this.pageIndex = Number(batches.headers.get('x-page-index'));

          this.app.setTriggerLoad(false);
        },
        (error) => {
            let errorMessage = "";

            if(error.status == 404) {
              errorMessage = "No Batch Found";
            } else if(error.status < 500) {
              errorMessage = JSON.stringify(error.error.message);
            } else {
              errorMessage = "P6 web service is not currently available. \n" + 
              "Please contact the support team: gdownstreamtarintegrationops@bp.com.";
            }
            
            this.dataSource = new MatTableDataSource<any>([{'batch_input':{'site':'', 'object':''}, 'batch_stats':'', 'status':'', 
              'message': errorMessage, 'batch_start_time':'','resources':''}]);
            
            this.dataSource.paginator = this.paginator;
            this.hasError = true;
            this.app.setTriggerLoad(false);
        } 
     );
  }

  /**
   * Get Related Projects / Batch Errors / Inputs from Nostra Sync API
   * @param batch_id     batch item to get object from
   * @param request_type request uri of the object to get: 
   *                     | Related Projects 'status/projects' 
   *                     | Batch errors     'errors' 
   *                     | Batch Input      '' 
   **/ 
  getBatchDetails(batch_id:string, request_type: string) {
    this.BatchesService.getBatchDetails(batch_id, request_type).subscribe(e => { 
      this.popUp = this.prettyPrintService.syntaxHighlight(e);
      return this.popUp; 
    })
  }

  /**
   * Search fields in table headers
   * @param value    $event.target.value of the form field that triggers this method
   * @param is_reset is reset button clicked
   **/ 
  filter(value: any, is_reset: boolean) {
    let newVal = this.form.value;
    // if search reset button clicked
    if(is_reset) { 
      // then empty all fields
      this.dataSource.filterPredicate = null; 
      this.formDirective.resetForm();
      this.form = this.app.buildForm(this.displayedColumns);
     } else {
      // else set filtering logic with values from fields
      this.dataSource.filterPredicate = (data: Batch) =>  
        (this.cut(data.batch_input['site']).indexOf(newVal.site) != -1 &&
         this.cut(data.batch_input['entity']).indexOf(this.cut(newVal.object)) != -1 && 
        (this.cut(data.message).includes(this.cut(newVal.message)) || 
         this.cut(data.batch_id).indexOf(this.cut(newVal.message)) != -1) && 
         data.status.indexOf(newVal.status) != -1 && 
        (this.dateDiff(data.batch_start_time, newVal.startTime))
      );
    }
    this.dataSource.filter = value; // triggers datasource filter action
  }

  /** 
   * Calculate date differences
   */ 
  dateDiff(batch_date: string, input_date) { 
    if(input_date != "") { 
      let input = new Date(input_date)
      let data  = new Date(batch_date.split("T")[0])
      return (input.getDate() == data.getDate() &&
              input.getMonth() == data.getMonth() &&
              input.getFullYear() == data.getFullYear()); 
    } else { return true; } }
  
  // String modifications
  splitTimeString(str:string) { return ( str.replace("T", `<hr>   `).replace(/\..*/,"")); }
  splitString(str:string) { return str.replace(/(?!^)([A-Z]|\d+)/g, " $1"); }
  cut(str:string) { return (str == null)? "" : str.trim().toLocaleLowerCase(); }
  
  /**
   * Open/Close Popup box after click on a error, project or input button
   * @param data         
   * @param request_type request uri of the object to get: 
   *                     | Related Projects 'status/projects' 
   *                     | Batch errors     'errors' 
   *                     | Batch Input      '' 
   **/ 
  openDialog(data: string, request_type: string) {
    this.isPopUpOpen = true; 
    this.popUp = "<span class='hourglass'>&#x231b; </span>Loading...";
    // if batch input button clicked
    if(request_type == '') { 
      // then display batch_input child of said batch item
      this.popUp = this.prettyPrintService.syntaxHighlight(data); 
    } else { 
      // else get errors/related projects from new request uri
      this.getBatchDetails(data, request_type); 
    }
  }
  close() {
    this.popUp = "Loading...";
    this.isPopUpOpen = false;
  }

  /**
   * Copy batch id / popup contents
   **/ 
  copy() {
    this.isCopied = true;
    setTimeout(() => {
      this.isCopied = false; 
    }, 1000);
  }

  /**
  * Back to top button in the bottom navigation
  **/ 
  backToTop() { document.querySelector('#body').scrollTo(0, 0); }
  
  /**
   * Set light / dark mode boolean
   */
  setColorMode() {
    this.colorService.setColorMode(this.colorService.getColorMode());
    this.check = this.colorService.getCheck();
  }
  
}


