import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from "@angular/cdk/overlay";
import { BrowserModule } from '@angular/platform-browser';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ReactiveFormsModule } from '@angular/forms';
import { MsalModule, MsalService, MsalInterceptor } from '@azure/msal-angular';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Angular Material
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// Main Components
import { BatchesComponent } from "@app/batches/batches.component";
import { UploaderComponent } from '@app/uploader/uploader.component';
// Local files
import { environment } from '@environments/environment';
import { AppComponent } from './app.component';
import { MessageService } from './_services/message.service';
import { appRoutingModule } from './app.routing';
import { HttpErrorHandler } from './_services/http-error-handler.service';
import { MsalApplicationModule } from '@app/_module/msal-application.module';
import { Base64Pipe, PrettyPrintPipe} from '@app/_helpers/base64.pipe';


import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    Base64Pipe,
    AppComponent,
    PrettyPrintPipe,
    BatchesComponent,
    UploaderComponent,
  ],
  imports: [
    NgbModule,
    OverlayModule,
    RouterModule,
    BrowserModule, 
    ClipboardModule,
    HttpClientModule, // For API
    appRoutingModule,
    BrowserAnimationsModule,
    // MS Authentication
    MsalModule,
    MsalApplicationModule,
    // Form
    FormsModule,
    ReactiveFormsModule,
    // Angular Material Mods
    MatIconModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule, 
    MatExpansionModule,
    MatSlideToggleModule,
  ],
  providers: [
    MsalModule,
    MsalService,
    HttpErrorHandler,
    MessageService, 
    MsalApplicationModule, 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
