import { Routes, RouterModule } from '@angular/router';

import { MsalGuard } from '@azure/msal-angular';
import { RoleGuardService } from '@app/_services/role-guard.service';
import { UploaderComponent } from '@app/uploader/uploader.component';
import { BatchesComponent } from "@app/batches/batches.component";

export const routes: Routes = [
    { path: '', component: BatchesComponent, canActivate: [MsalGuard]},
    { path: 'ms-update', component: UploaderComponent, canActivate: [MsalGuard, RoleGuardService],
        data: { expectedRole: ['lin', 'gsk', 'rot'] }}, // roles can be found in BP Azure app registration
    { path: '**', redirectTo: '' } // otherwise redirect to BatchesComponent
];

export const appRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
