<body id="body">
  <div class="card-body">
    <div class="d-inline-flex w-100 justify-content-between position-relative">
      <!-- TITLE -->
      <h1 id="pg-title" class="row">BATCH HISTORY</h1>
      <!-- COLOR MODE TOGGLE -->
      <div class="row float-right mr-2">
        <mat-slide-toggle [checked]="false" [(ngModel)]="check" (ngModelChange)="setColorMode()" 
          ngDefaultControl matTooltip="{{this.colorService.getColorMode() | titlecase}} on"></mat-slide-toggle>
      </div>
    </div>
    <!-- BOTTOM NAVIGATION BAR -->
    <div class="sticky">
      <!-- PAGINATOR -->
      <mat-paginator #paginator [pageSizeOptions]="[5, 10, 25, 100]" 
                                [length]="count" 
                                [pageSize]="pageSize"
                                [pageIndex]="pageIndex"
                                (page)="getBatches(false)"
                                showFirstLastButtons></mat-paginator>
      <!-- BACK TO TOP BUTTON -->
      <button id="back-to-top-btn" class="btn btn-sucess round" (click)="backToTop()"  matTooltip="Back to top">
        <mat-icon>expand_less</mat-icon></button>
      <!-- REFRESH BUTTON -->
      <button id="refresh-btn" class="btn btn-info" (click)="getBatches(false)" matTooltip="Refresh">
        <mat-icon>autorenew</mat-icon></button>
      <!-- RESET ALL BUTTON -->
      <button id="reset-btn" class="btn btn-danger" (click)="filter('', true);" matTooltip="Reset Search">
        <mat-icon>youtube_searched_for</mat-icon></button>
    </div>

    <!-- TABLE -->
    <form [formGroup]="form" #formDirective="ngForm">
      <table class="table" [dataSource]="dataSource" mat-table matSort>
        <!-- Site Column -->
        <ng-container matColumnDef="site">
          <th mat-header-cell *matHeaderCellDef>
            <!-- table header search -->
            <input type="search" 
                   id="site" 
                   placeholder="SITE"
                   autocomplete="off" 
                   formControlName="site"  
                   (keyup)="filter($event.target.value, false)" 
                   (search)="filter($event.target.value, false)">
              <mat-icon>search</mat-icon>
          </th>
          <td mat-cell *matCellDef="let item" [ngClass]="[(item.status=='')? 'running border-0': (item.status | lowercase)]"> 
            {{item.batch_input.site}} </td>
        </ng-container>
        <!-- Object Column -->
        <ng-container matColumnDef="object">
          <th mat-header-cell *matHeaderCellDef>
            <!-- table header search -->
            <input type="search" 
                   id="object" 
                   placeholder="OBJ"
                   autocomplete="off" 
                   formControlName="object" 
                   (keyup)="filter($event.target.value, false)" 
                   (search)="filter($event.target.value, false)">
            <mat-icon>search</mat-icon>
          </th>
          <td mat-cell *matCellDef="let item" [ngClass]="[(item.status=='')? 'running': (item.status | lowercase)]"> 
            {{item.batch_input.entity}} </td>
        </ng-container>
        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <!-- table header dropdown -->
            <select formControlName="status" (change)="filter($event.target.options.selectedIndex, false)">
              <option value="">STATUS</option> 
              <option value="SUCCESSFUL">SUCCESSFUL</option>
              <option value="ERRORS">ERRORS</option><option value="RUNNING">RUNNING</option>
            </select>
          </th>
          <!-- status icon displays -->
          <td class="col-status" mat-cell *matCellDef="let item" [ngClass]="[(item.status=='')? 'running': (item.status | lowercase)]"> 
            <img class="mx-auto ms-auto d-block" *ngIf="item.status == 'SUCCESSFUL'" src="../../assets/success.png" matTooltip="{{item.status}}" height="40px"/>
            <img class="mx-auto ms-auto d-block" *ngIf="item.status == 'ERRORS'" src="../../assets/fail.png" matTooltip="{{item.status}}" height="40px"/>
            <span class="mx-auto ms-auto d-block" *ngIf="item.status == 'RUNNING'" class="hourglass" matTooltip="{{item.status}}">&#x231b;</span>
          </td>
        </ng-container>
        <!-- Message Column -->
        <ng-container matColumnDef="message">
          <th mat-header-cell *matHeaderCellDef>
            <!-- table header search -->
            <input type="search" 
                   id="message" 
                   placeholder="BATCH ID / MESSAGE"
                   autocomplete="off" 
                   formControlName="message" 
                   (keyup)="filter($event.target.value, false)" 
                   (search)="filter($event.target.value, false)">
              <mat-icon>search</mat-icon> 
          </th>
          <td class="col-message" mat-cell *matCellDef="let item" [ngClass]="[(hasError)? 'text-danger text-center ml-0 mr-5 running': (item.status | lowercase)]"> 
              {{item.message}} 
            <!-- OPEN ERROR BUTTON -->
            <button class="btn btn-link open-error-button" 
                    (click)="openDialog(item.batch_id, 'errors')"
                    *ngIf="item.status == 'ERRORS'" 
                    matTooltip="View All Errors" [matTooltipPosition]="'right'">
                <mat-icon>launch</mat-icon>
            </button>
            <br>
            <!-- COPY BATCH ID BUTTON -->
            <button class="copy-container" 
                    (click)="copy()" 
                    matTooltip="{{isCopied? item.batch_id: 'Copy Batch ID to clipboard'}}" 
                    [matTooltipPosition]="'right'"
                    [cdkCopyToClipboard]="item.batch_id" >
              <small>{{item.batch_id}} </small>
              <div class="copy"><mat-icon>{{isCopied? 'done': 'content_copy'}}</mat-icon> </div>
            </button>
          </td>
        </ng-container>
        <!-- REMOVED column -->
        <ng-container matColumnDef="batchId" class="d-none">
          <th style="display: none;" mat-header-cell *matHeaderCellDef>  </th>
          <small style="display: none;" *matCellDef="let item">{{item.batch_id}} </small>
        </ng-container>

        <!-- Start Time Column -->
        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef>
            <input type="text"
                   (change)="filter($event.target.value, false)" 
                   onFocus="(this.type='date')" 
                   onBlur="(this.type='text')"
                   placeholder="START TIME (UTC+0)"
                   formControlName="startTime">
          </th>
          <td class="col-time" mat-cell 
              *matCellDef="let item" 
              [ngClass]="[(item.status=='')? 'running border-0': (item.status | lowercase)]"
              [innerHTML]="splitTimeString(item.batch_start_time)"> </td>
        </ng-container>

        <!-- Details Column -->
        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef> DETAILS </th>
          <td class="col-details" mat-cell *matCellDef="let item" 
              [ngClass]="[(item.status=='')? 'running': (item.status | lowercase)]">
            
            <!-- Batch Input Button -->
            <button type="button"
                    class="batch-input-btn" 
                    matTooltip="Batch Input" 
                    *ngIf="item.status !=''" 
                    (click)="openDialog(item.batch_input, '')">
              <mat-icon>system_update_alt</mat-icon></button>
            <!-- Related Project Button -->
            <button type="button" 
                    class="related-projects-btn" 
                    matTooltip="Related Projects"
                    *ngIf="item.status !=''"  
                    (click)="openDialog(item.batch_id, 'status/projects')">
              <mat-icon>format_list_bulleted</mat-icon></button>
         
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <!-- POP UP Box -->
      <section id="pop-up-box" [ngClass]="[(isPopUpOpen)? 'opacity-100 d-block': 'opacity-0 d-none']">
        <!-- CONTENT -->
        <pre [innerHTML]="this.popUp"></pre>

        <footer>
          <hr/> 
          <!-- CLOSE BUTTON -->
          <button class="btn btn-close btn-danger float-right" (click)="close()">Close</button>
          &emsp;
          <!-- COPY BUTTON -->
          <button class="btn btn-outline float-right" 
                  (click)="copy()" 
                  matTooltip="{{isCopied? 'Copied': 'Copy to clipboard'}}"
                  [cdkCopyToClipboard]="[this.popUp | prettyprint]" 
                  [matTooltipPosition]="'right'">
            <div class="copy"> {{isCopied? 'Copied': 'Copy to clipboard'}} <mat-icon>{{isCopied? 'done': 'content_copy'}}</mat-icon> </div>
          </button>
        </footer>
      </section> <!-- END OF POPUP BOX -->
    </form> <!-- END OF TABLE -->
  </div> 
</body>
  
