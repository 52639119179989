import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class PrettyPrintService {
  
    output(input: string) {
        document.body.appendChild(document.createElement('pre')).innerHTML = input;
    }

    public syntaxHighlight(json): string {
        let jsonStr = JSON.stringify(json, null, 4).replace("\\", "");
        jsonStr = jsonStr.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return jsonStr.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                } else {
                    cls = 'string';
                }
            } else if (/true|false/.test(match)) {
                cls = 'boolean';
            } else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    }
}
