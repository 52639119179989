import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from '../_services/http-error-handler.service';
import { environment } from '@environments/environment';
import { Batch } from '@app/_models/batch';

const httpOptions = {
  headers: new HttpHeaders({
    observe: 'response'
  })
};

@Injectable()
export class BatchesService {
  private baseUrl = `${environment.apiUrl}/ui/batch`;
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('BatchesService');
  }

  /**
   * GET all batches from the server
   * @param page_size  The number of records per page, defined by user, default is 5
   * @param page_index The page number user is currently requesting, default is 1
   * @returns A full HTTP response where the response body is a collection of Batch objects, see src/app/_models/batch.ts
   */
  getBatches(page_size: number, page_index: number): Observable<HttpResponse<Batch[]>> {
    return this.http.get<Batch[]>(
      `${this.baseUrl}/all/status?pageSize=${page_size}&pageIndex=${page_index}`, 
        { headers: new HttpHeaders()
          .set('Content-Type', 'application/json'),
          observe: 'response'
        })
      .pipe(
          timeout(360000))
  }

  /**
   * GET projects or errors for each batch from the server 
   * @param batch_id     The batch to get details from
   * @param request_type request endpoint of object to get
   * @returns A collection of JSON objects
   */
  getBatchDetails(batch_id:string, request_type:string): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/${batch_id}/${request_type}`, httpOptions)
      .pipe(
        timeout(360000),
        catchError(this.handleError('getBatchDetails', ['Data Not Available']))
      );
  }
}
