import { Component, OnInit } from '@angular/core';
import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';

import { AppComponent } from '@app/app.component';
import { UploaderService } from "@app/uploader/uploader.service";
import { ColorModeService } from '../_services/color-mode.service';

export function toResponseBody<T>() {
  return pipe(
    filter(( event: HttpEvent<T> ) => event.type === HttpEventType.Response),
    map(( res: HttpResponse<T> ) => res.body)
  );
}

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  providers: [UploaderService],
  styleUrls: ['./../app.component.scss', './uploader.component.scss']
})

export class UploaderComponent implements OnInit{
  check: boolean;
  form: FormGroup;

  sites: object[];
  site: string = "";
  
  isScheduled: boolean;
  hasSiteError: boolean;
  hasProjectError: boolean;
  errorMessage: String = "";

  nostraProjects: any;
  displayedColumns: any[] = ['site', 'project'];

  constructor( private authService: MsalService, private uploaderService: UploaderService, 
               public colorService: ColorModeService, private app: AppComponent) {}

  ngOnInit(): void {
    this.colorService.load('body > app-root > html'); // for .spec testing, change to 'html' 
    this.getSites();
    this.clearResponse(); // makes sure all table contents are clear
    this.check = this.colorService.getCheck();

    this.form = this.app.buildForm(this.displayedColumns);
    this.form.get('site').disable(); // enable only when user has chosen a site

    this.isScheduled = false; // has sync button been clicked
  }

  // Get all sites available for the user's role
  getSites(): void {
    this.app.setTriggerLoad(true);
    this.uploaderService.authorize().subscribe(
      (response) => {
        this.sites = response; 
        this.hasSiteError = false;
        this.form.get('site').enable();
        this.app.setTriggerLoad(false);
      },
      (error) => {
        this.errorMessage = (error.status < 500)? error.error.message : 
          "P6 web service is not currently available. \n" + 
          "Please contact the support team: gdownstreamtarintegrationops@bp.com.";
        this.hasSiteError = true;
        this.app.setTriggerLoad(false);
      } 
    );
    
  }

  // On synchronise button click, trigger an upload
  sync(): void {
    this.clearResponse();
    this.app.setTriggerLoad(true); // open loading spinner
    
    this.site = this.form.value.site;
    this.uploaderService.sync(this.site)
      .subscribe(
        (response) => {
          this.isScheduled = true;
          this.nostraProjects = response; 
          this.app.setTriggerLoad(false);
          //console.log(this.nostraProjects);
          this.hasProjectError = false;
        },
        (error) => {
          this.errorMessage = (error.status < 500)? error.error.message : 
            "P6 web service is not currently available. \n" + 
            "Please contact the support team: gdownstreamtarintegrationops@bp.com.";
          this.app.setTriggerLoad(false);
          this.hasProjectError = true;
        }
      );
  }

  // On refresh button click, refresh the status of upload
  refresh(batch_id:string) {
    this.clearResponse();
    this.app.setTriggerLoad(true); // open loading spinner

    this.uploaderService.refresh(batch_id).subscribe(
      (response) => {
        this.nostraProjects = response[0]; 
        this.app.setTriggerLoad(false);
        this.hasProjectError = false;
      },
      (error) => {
        this.errorMessage = (error.status < 500)? error.error.message : 
          "P6 web service is not currently available. \n" + 
          "Please contact the support team: gdownstreamtarintegrationops@bp.com.";
        this.app.setTriggerLoad(false);
        this.hasProjectError = true;
      }
    );
  }

  getAccountRoles(){
    // @ts-ignore
    return this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles;
  }

  // Set light / dark mode boolean
  setColorMode() {
    this.colorService.setColorMode(this.colorService.getColorMode());
    return this.colorService.getCheck();
  }

  // clear table contents for a new upload 
  clearResponse() {
    this.nostraProjects = {"batch_id": "", "status": "", "message": "", "projects": [{}]};
    this.errorMessage = "";
  }
}


