// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api-001-nonprod.testweb.bp.com/dev/iepb-tarinostrasync/papi/v1/api',
  uiUrl: 'https://nostrabatch-dev.bpglobal.com',  // http://localhost:4200/ 'https://nostrabatch-dev.bpglobal.com',
  clientId: 'b5d3d8c4-babf-458d-b9d8-87b5a6090fe0',
  authority: 'https://login.microsoftonline.com/ea80952e-a476-42d4-aaf4-5457852b0f7e',
  unprotectedResources: [],
  consentScopes: [
    'https://api-001-nonprod.testweb.bp.com/dev/iepb-tarinostrasync/papi/v1/Primavera',
    'openid',
    'profile'
  ] 
};

export const protectedResourceMap: [string, string[]][] = [
  [
    'https://api-001-nonprod.testweb.bp.com',
    [
        'https://api-001-nonprod.testweb.bp.com/dev/iepb-tarinostrasync/papi/v1/Primavera'
    ]
  ]
];

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
