import {Injectable, Renderer2, RendererFactory2} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorModeService {

    public rd: Renderer2;
    private check: boolean;
    private colorMode: string;
    private page: string;

    constructor(rendererFactory: RendererFactory2) {
        this.rd = rendererFactory.createRenderer(null, null);
    }

    init() {
        if (localStorage.getItem('colorMode') == "darkMode" || 
                window.matchMedia("(prefers-color-scheme: dark)").matches) {
            this.colorMode = 'darkMode';
            this.check = true;
        } else {
            this.colorMode = 'lightMode';
            this.check = false;
        }
    }

    getColorMode() {
        if (this.colorMode == null) {
            this.init();
        } 
        return this.colorMode;
    }

    getCheck() { return this.check; }

    load(pageSelector: string) {
        this.getColorMode();
        this.page = pageSelector
        this.check = (this.getColorMode() == 'darkMode')? true : false;
        this.rd.addClass(document.querySelector(pageSelector), this.colorMode);
    }

    setColorMode(oldCm: string) {
        this.colorMode = (oldCm == 'darkMode')? 'lightMode':'darkMode';
        localStorage.setItem('colorMode', this.colorMode);
        this.rd.removeClass(document.querySelector(this.page), oldCm);
        this.rd.addClass(document.querySelector(this.page), this.colorMode);
        this.check = !this.check;
    }
}