import { Component, Input, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, BrowserCacheLocation, EventMessage, EventType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'nostra-batch-ui';
  isIframe = false;
  //loginDisplay = false;
  loggedIn = false;
  triggerLoad: boolean;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    
    public authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.setLoggedIn();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.setLoggedIn();
    });
  }

  /**
   * Boolean for displaying page load spinner, used when loading or refreshing batches/available sites
   * @param value true  = turn on 
   *              false = turn off 
   **/ 
  getTriggerLoad() {
    return this.triggerLoad;
  }
  setTriggerLoad(value: boolean) {
    this.triggerLoad = value;
  }

  /**
   * Build form field for batch table filters, and available sites dropdown
   * @returns form field mappings
   */
  buildForm(columns: any[]) {
    let tempControl = new FormGroup({ });
    columns.forEach(i => tempControl.addControl(i, new FormControl('')));
    return tempControl;
  }

  // Microsoft login
  setLoggedIn() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }
  getAccountRoles(){
    // @ts-ignore
    return this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles;
  }
  loginRedirect() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }
  loginPopup() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
      });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/'
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
