import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from '../_services/http-error-handler.service';
import { environment } from '@environments/environment';
import { NostraProject } from '@app/_models/nostraProject';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable()
export class UploaderService {
  nostraProjectsUrl = `${environment.apiUrl}`;
  private handleError: HandleError;

  constructor(
    private http: HttpClient,
    httpErrorHandler: HttpErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('UploaderService');
  }

  /**
   * CREATE project uploads to be synced with Nostram based on the site user chooses
   * @param site The site chosen by user to upload projects for
   * @returns A HTTP response of whether the request is successful
   */
  sync(site: string): Observable<any> {
    let sites = site.toLocaleLowerCase();
    return this.http.post(`${this.nostraProjectsUrl}/ui/sites/${sites}/milestones/sync`, httpOptions)
      .pipe(
        timeout(360000)
      );
  }

  /** GET Nostra projects from the server */
  authorize(): Observable<any> {
    return this.http.post(`${this.nostraProjectsUrl}/ui/authorize`, httpOptions)
      .pipe(
        timeout(360000)
      );
  }

  /**
   * GET The newest projects uploaded to Nostra, for when refresh project button is clicked 
   * @param batch_id The batch of projects to be uploaded
   * @returns A HTTP response of whether the request is successful
   */
  refresh(batch_id: string): Observable<any> {
    return this.http.get(`${this.nostraProjectsUrl}/ui/batch/${batch_id}/status/projects`, httpOptions)
    .pipe(
      timeout(360000)
    );
  }
}
