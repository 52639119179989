import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {AccountInfo} from '@azure/msal-browser';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private authService: MsalService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.expectedRole;

    const account: AccountInfo = this.authService.instance.getAllAccounts()[0];
    
    // @ts-ignore
    // console.log(expectedRole + " " + account.idTokenClaims?.roles)

    // @ts-ignore
    if (!account.idTokenClaims?.roles) {
      window.alert('Token does not have roles claim. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
      return false;
    } else {
      // @ts-ignore
      var roleCheck = (expectedRole == null) || (Array.isArray(expectedRole) && account.idTokenClaims?.roles.filter(value => expectedRole.includes(value).length > 0));
      if (!roleCheck) {
          window.alert('You do not have access as expected role is missing. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
          return false;
      }
    }

    return true;
  }

  canSee(): boolean {
    // @ts-ignore
    if (!this.authService.instance.getAllAccounts()[0].idTokenClaims?.roles) {
      window.alert('Token does not have roles claim. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.');
      return false;
    }
    return true;
  }
}
